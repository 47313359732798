import { useState } from 'react'
import { Typography } from '@material-ui/core'

import { Widget as TypeformWidget } from '@typeform/embed-react'

import styles from './AppStoreCard.module.scss'
import { AppStoreCardProps } from './AppStoreCard.types'
import { useTranslation } from 'react-i18next'
import { useAuthState, useMarketplaceAnalytics } from '@percent/cause-dashboard/common/hooks'
import { MarketplaceProgrammeVisitedSource } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'
import { Button, ButtonText, Icon, Modal, ModalHeader, ModalTitle } from '@percent/lemonade'
import {
  PrimaryButtonLink,
  ProductOfferType,
  SecondaryButtonLink
} from '@percent/cause-dashboard/app/productOffers/data/data.types'
import { PressEvent } from '@react-types/shared'
import AppStoreSuccessModal from './AppStoreSuccessModal/AppStoreSuccessModal'
import { CalendlyContainer } from '@percent/cause-dashboard/common/components'
import goodstackImage from '@percent/cause-dashboard/common/assets/images/goodstack.png'

export function AppStoreCard({
  name,
  description,
  category,
  type,
  logo,
  primaryButton,
  secondaryButton,
  selectedOfferName,
  selectOffer,
  tracking
}: Readonly<AppStoreCardProps>) {
  const { t } = useTranslation()
  const { authState } = useAuthState()
  const { trackProgramVisited: track } = useMarketplaceAnalytics()
  const [openTypeformModal, setOpenTypeformModal] = useState<string | undefined>(undefined)
  const [hasSubmittedTypeform, setHasSubmittedTypeform] = useState(false)

  const hasBuyNowButton =
    primaryButton.linkText === 'productOffers.buyNow' || secondaryButton?.linkText === 'productOffers.buyNow'

  const getPrimaryLink = (button: PrimaryButtonLink) => {
    return button.link ?? ''
  }

  const getSecondaryLink = (button: SecondaryButtonLink) => {
    return button?.link ?? ''
  }

  const getTypeformIFrame = (primaryButton: PrimaryButtonLink, secondaryButton: SecondaryButtonLink) =>
    primaryButton.linkType === 'typeform'
      ? primaryButton.typeformId
      : secondaryButton?.linkType === 'typeform'
      ? secondaryButton.typeformId
      : undefined

  const getCalendlyAvailability = (primaryButton: PrimaryButtonLink, secondaryButton: SecondaryButtonLink) =>
    primaryButton.linkType === 'calendly' || secondaryButton?.linkType === 'calendly'

  const primaryLink = getPrimaryLink(primaryButton)
  const secondaryLink = getSecondaryLink(secondaryButton)
  const isCalendlyCallAvailable = getCalendlyAvailability(primaryButton, secondaryButton)
  const typeformIFrame = getTypeformIFrame(primaryButton, secondaryButton)

  const trackProgramVisited = async (
    link: string,
    linkText: string,
    source: MarketplaceProgrammeVisitedSource = MarketplaceProgrammeVisitedSource.Card
  ) =>
    track({
      source,
      targetUrl: link,
      programmeOfferName: name,
      productCategory: category,
      programTracking: tracking,
      metadata: {
        hasBuyNowOption: hasBuyNowButton,
        offerType: type,
        offerName: name,
        originCategory: category,
        buttonText: linkText
      }
    })

  const handleOnClick = async (event: PressEvent, link: string, linkText: string, button: SecondaryButtonLink) => {
    await trackProgramVisited(link, linkText, MarketplaceProgrammeVisitedSource.Card)

    if (typeformIFrame) {
      setOpenTypeformModal(typeformIFrame)
    } else if (button?.linkType === 'external') {
      window.open(link, '_blank')
    } else if (isCalendlyCallAvailable) {
      selectOffer(name)
    }
  }

  const handleCloseTypeformModal = () => {
    setOpenTypeformModal(undefined)
    setHasSubmittedTypeform(false)
  }

  return (
    <>
      <div className={styles.appStoreCardContainer}>
        <div className={styles.cardDetails}>
          <div className={styles.cardLogoWrapper}>
            {type === ProductOfferType.PercentService && (
              <img src={goodstackImage} alt={name} className={styles.image} />
            )}
            {logo && <img src={logo} alt={name} className={styles.image} />}
          </div>
          <Typography variant="h6" color="secondary" className={styles.tileName}>
            {name}
          </Typography>
          {!!description && (
            <Typography data-testid="tile-description" className={styles.tileDescription}>
              {description}
            </Typography>
          )}
        </div>
        <div className={styles.cardCta}>
          {secondaryButton && (
            <ButtonText
              onPress={async event => {
                await handleOnClick(event, secondaryLink, t(secondaryButton.linkText), secondaryButton)
              }}
              data-testid={'offer_' + name}
            >
              {t(secondaryButton.linkText)}
            </ButtonText>
          )}
          <Button
            onPress={async event => {
              await handleOnClick(event, primaryLink, t(primaryButton.linkText), primaryButton)
            }}
            data-testid={'offer_' + name}
            variant={'secondary'}
            size={'medium'}
            stretch
          >
            {t(primaryButton.linkText)}
          </Button>
        </div>
      </div>
      {hasSubmittedTypeform && (
        <AppStoreSuccessModal
          trackingData={{
            offerName: name,
            categoryName: category,
            hasBuyNowOption: hasBuyNowButton
          }}
          offerType={type ?? ProductOfferType.PercentService}
          open={hasSubmittedTypeform}
          onClose={handleCloseTypeformModal}
        />
      )}

      {isCalendlyCallAvailable && name === selectedOfferName && (
        <CalendlyContainer
          url={primaryButton.linkType === 'calendly' ? primaryLink : secondaryLink}
          selectItem={selectOffer}
          tracking={{ source: MarketplaceProgrammeVisitedSource.Card, programmeName: name }}
        />
      )}

      {openTypeformModal && !hasSubmittedTypeform && (
        <Modal width={800} open={Boolean(openTypeformModal)} onClose={handleCloseTypeformModal}>
          <ModalHeader>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexGrow: 1,
                padding: 4,
                width: '100%'
              }}
            >
              <ModalTitle>{name}</ModalTitle>
              <button
                style={{
                  padding: 0,
                  border: 'none',
                  background: 'none',
                  marginLeft: 'auto'
                }}
                onClick={handleCloseTypeformModal}
                data-testid={'typeform-dialog'}
              >
                <Icon name="close" size={4} color="gray600" />
              </button>
            </div>
          </ModalHeader>
          <TypeformWidget
            id={openTypeformModal}
            data-testid={'typeform-widget'}
            hidden={{
              organisation_id: authState.claimOrganisation?.id ?? 'unknown',
              email: authState.user?.email ?? 'unknown',
              account_id: authState.user?.id ?? 'unknown'
            }}
            className={styles.typeformWidget}
            onSubmit={() => setHasSubmittedTypeform(true)}
          />
        </Modal>
      )}
    </>
  )
}
