import { AuthStateType } from '@percent/cause-dashboard/context/auth/authContext/AuthContext.types'
import { selectAuthState } from '@percent/cause-dashboard/context/auth'
import { ProductOffer } from '@percent/cause-dashboard/app/productOffers/data/data.types'

const COUNTRIES_TO_ALLOW_BOOK_CALL_FLOW = ['USA', 'CAN', 'AUS', 'FRA', 'GBR']

const canUserBookCall = (authState: AuthStateType) => {
  const { claimAccepted } = selectAuthState(authState)
  const myCountry = authState.organisation?.countryCode || authState.claimOrganisation?.countryCode
  const acceptCallsFromMyCountry = COUNTRIES_TO_ALLOW_BOOK_CALL_FLOW.includes(myCountry || '')

  const canBookCall = claimAccepted && acceptCallsFromMyCountry

  return canBookCall
}

const shouldDisplayOffer = (productOffer: ProductOffer, canBookCall: boolean) => {
  const isCalendlyCTA =
    productOffer.primaryButton.linkType === 'calendly' || productOffer.secondaryButton?.linkType === 'calendly'

  return productOffer.globalOverride || canBookCall || !isCalendlyCTA
}

export { canUserBookCall, shouldDisplayOffer }
