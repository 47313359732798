import { AxiosInstance } from 'axios'
import { Alpha3Code } from 'i18n-iso-countries'

export type BankDetailsServiceCapabilities = {
  percentClient: AxiosInstance
}

export enum BankDetailStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export type BankDetailStatusTypes =
  | BankDetailStatus.PENDING
  | BankDetailStatus.APPROVED
  | BankDetailStatus.REJECTED

export enum WireTransferType {
  DOMESTIC = 'DOMESTIC',
  IBAN = 'IBAN',
  DOMESTIC_WITH_BIC = 'DOMESTIC_WITH_BIC',
}

export type BankDetailsValueWithLabel = {
  value: string
  label: string
}

export type BankAccountWireTransferDetails = {
  type?: WireTransferType
  accountNumber?: BankDetailsValueWithLabel
  bankCode?: BankDetailsValueWithLabel
  iban?: BankDetailsValueWithLabel
  bic?: BankDetailsValueWithLabel
  bicSwift?: BankDetailsValueWithLabel
  branchCode?: BankDetailsValueWithLabel
}

export type BankAccountDetails = {
  version?: string
  holderName?: string
  wireTransferDetails: BankAccountWireTransferDetails
}

export type RequiredBankAccountDetailsFields = {
  [fieldName: string]: {
    placeholder: string
    errorMessage: string
    label: string
    regex: string
    required: boolean
    hint?: string
  }
}

export type BankDetail = {
  claimer?: {
    email: string
  }
  countryCode?: Alpha3Code
  organisationId?: string
  organisationName?: string
  status?: BankDetailStatusTypes
  requestDate?: string
  previousFilename?: string
  bankAccountDetails?: BankAccountDetails
  requiredBankAccountDetails?: {
    type: WireTransferType
    fields: RequiredBankAccountDetailsFields
  }
  addBankDetailsFormLabels?: {
    type: WireTransferType
    fields: RequiredBankAccountDetailsFields
  }
}

export type PostBankDetailsProps = {
  payload: {
    formData: object
  }
}

export type BankDetailsValidationError = {
  field: string
  message: string
}

export type BankDetailsValidationResult = {
  success: boolean
  validationErrors: BankDetailsValidationError[]
  branch?: {
    bankName?: string
    branchName?: string
    address?: {
      addressLine1?: string
      addressLine2?: string
      city?: string
      postalCode?: string
      state?: string
      country?: string
    }
  }
}

export type BankDetailsValidationEntry = {
  key: string
  value: string
}

export type BankDetailsFieldData = {
  placeholder: string
  field: string
  label: string
  hint: string | undefined
  required: boolean
}
