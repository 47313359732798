import { useFormik } from 'formik'
import {
  BankAccountWireTransferDetails,
  BankDetail,
  BankDetailStatus,
  PostBankDetailsProps,
} from '../types/bankDetails.types'
import { bankDetailsViewSchema } from '../components/bankDetailsView/BankDetailsViewSchema'

type UseAddBankDetailsFormProps = {
  bankDetailsData: BankDetail | null
  t: (id: string) => string
  apiFunc: (payload: PostBankDetailsProps) => Promise<() => void> | (() => void)
  organizationId?: string
  selectedFile?: File
}

type FormValues = {
  holderName: string | undefined
  accountNumber?: string
  bankCode?: string
  branchCode?: string
  bic?: string
  bicSwift?: string
  iban?: string
}

export const useAddBankDetailsForm = ({
  bankDetailsData,
  t,
  apiFunc,
  organizationId,
  selectedFile,
}: UseAddBankDetailsFormProps) => {
  const bankAccountDetails = bankDetailsData?.bankAccountDetails
  const addBankDetailsFormLabels = bankDetailsData?.addBankDetailsFormLabels
  const isNotRejected = bankDetailsData?.status !== BankDetailStatus.REJECTED

  const initialValues = Object.entries(
    addBankDetailsFormLabels?.fields || {}
  ).reduce(
    (acc, [field]) => ({
      ...acc,
      [field]: isNotRejected
        ? bankAccountDetails?.wireTransferDetails?.[
            field as Exclude<keyof BankAccountWireTransferDetails, 'type'>
          ]?.value
        : '',
    }),
    {}
  )

  const organisationName = bankDetailsData?.organisationName
  const bankDetailsHolderName = isNotRejected
    ? bankAccountDetails?.holderName
    : ''
  const defaultHolderName =
    Number(organisationName?.length) <= 50 ? organisationName : ''

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      holderName: bankDetailsHolderName || defaultHolderName,
      ...initialValues,
    },
    validationSchema: addBankDetailsFormLabels?.fields
      ? () =>
          bankDetailsViewSchema({
            t,
            addBankDetailsFormLabels,
          })
      : undefined,
    onSubmit: async (value: FormValues) => {
      const formData = new FormData()

      if (!addBankDetailsFormLabels?.fields && selectedFile) {
        formData.append('proofOfBankDetails', selectedFile)
      }

      if (addBankDetailsFormLabels?.fields && selectedFile) {
        const paymentDetails = Object.keys(
          addBankDetailsFormLabels?.fields || {}
        ).reduce((acc, key) => {
          const details = value[key as keyof FormValues]

          if (details)
            return {
              ...acc,
              [key]: details,
            }

          return acc
        }, {})

        const bankAccountDetails = {
          holderName: value.holderName,
          paymentDetails: {
            type: addBankDetailsFormLabels?.type,
            ...paymentDetails,
            bic: value.bic || value.bicSwift,
          },
        }

        formData.append('proofOfBankDetails', selectedFile)
        formData.append(
          'bankAccountDetails',
          JSON.stringify(bankAccountDetails)
        )
      }

      if (organizationId) {
        formData.append('organisationId', organizationId)
      }

      await apiFunc({
        payload: {
          formData,
        },
      })
    },
  })

  return {
    formik,
    fields: Object.entries(addBankDetailsFormLabels?.fields || {})
      .map(([field, { label, hint, required, placeholder }]) => ({
        placeholder,
        field,
        label,
        hint,
        required,
      }))
      .sort((a, b) => Number(b.required) - Number(a.required)),
  }
}
