import styles from './HomePage.module.scss'
import { Button, Text } from '@percent/lemonade'
import { AppStoreCard } from '@percent/cause-dashboard/app/productOffers/AppStoreCard/AppStoreCard'
import { percentServices } from '@percent/cause-dashboard/app/productOffers/data/percentServices'
import { productOffers } from '@percent/cause-dashboard/app/productOffers/data/productOffers'
import { CategoryName } from '@percent/cause-dashboard/common/hooks/useCausesDashboardAnalytics/marketplaceAnalytics.types'
import { useHistory } from 'react-router-dom'
import { RoutePath } from '@percent/cause-dashboard/app/routes/Routes'
import { ProfileCompletionProgress } from '@percent/cause-dashboard/app/home/profileCompletionProgress/ProfileCompletionProgress'
import { useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useCalendlyEventListener } from 'react-calendly'
import { useState } from 'react'
import {
  canUserBookCall,
  shouldDisplayOffer
} from '@percent/cause-dashboard/common/utility/productOffers/productOffers'
import LeftIcon from '@percent/cause-dashboard/assets/images/home/left.png'
import RightIcon from '@percent/cause-dashboard/assets/images/home/right.png'
import RejectedImage from '@percent/cause-dashboard/assets/images/home/rejected-image.png'
import { useTranslation } from 'react-i18next'

const offers = [
  percentServices.socialMediaManagement,
  percentServices.googleAdsSupport,
  productOffers.constantContact,
  productOffers.gusto,
  productOffers.givebutter
]

const MAX_OFFERS_COUNT = 3

export const HomePage = () => {
  const { authState } = useAuthState()
  const { push } = useHistory()
  const { t } = useTranslation()

  const isRejected = !!authState.claimOrganisation?.rejectedAt
  const { calendlyService } = useServices()
  const [{ isLoading: isCalendlyServiceLoading }, { apiFunc: saveMeetingLink }] = useMutation(
    calendlyService.saveMeetingLink
  )
  const [selectedOfferName, setSelectedOfferName] = useState<string | undefined>(undefined)

  // Copy pasta from ProductOffersPage
  // TODO: find a way to encapsulate this calendly thing inside of AppStoreCard

  useCalendlyEventListener({
    onEventScheduled: event => {
      const uri = event.data.payload.event.uri
      if (uri && !isCalendlyServiceLoading) {
        saveMeetingLink({
          accountId: authState.user?.id,
          organisationId: authState.user?.organisationId ?? '',
          email: authState.user?.email,
          source: selectedOfferName ?? 'app-store-offer',
          meetingLink: uri
        })
      }
      setSelectedOfferName(undefined)
    }
  })

  const offersToDisplay = offers
    .filter(offer => shouldDisplayOffer(offer, canUserBookCall(authState)))
    .slice(0, MAX_OFFERS_COUNT)

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.wavingHandBackground}>
          <span className={styles.wavingHand} role="img" aria-label="waving hand">
            👋
          </span>{' '}
        </div>
        <span>{t('typography.home.title')}</span>
      </div>
      {isRejected ? (
        <div>
          <div className={styles.descriptionCard}>
            <div className={styles.rejectedContainer}>
              <img className={styles.rejectedImage} src={RejectedImage} alt="" />
              <div className={styles.rejectedDescription}>
                <Text className={styles.title}>
                  <span role="img" aria-label="sad face">
                    ☹️
                  </span>{' '}
                  {t('typography.home.rejected')}
                </Text>
                <Text>{t('typography.home.rejectedDescription')}</Text>
                <Button variant="primary">{t('typography.home.rejectedButton')}</Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.descriptionCard}>
            <img src={LeftIcon} alt="" className={styles.leftIcon} />
            <img src={RightIcon} alt="" className={styles.rightIcon} />
            <Text size="medium" fontWeight="semiBold" align="center" className={styles.upperCaseTitle} color="#0D0C0F">
              {t('typography.home.saveMoney')}
            </Text>
            <span className={styles.discountsTitle}>{t('typography.home.saveMoneyTitle')}</span>
            <Text size="large" align="center" className={styles.discountsDescription}>
              {t('typography.home.saveMoneyDescription')}
            </Text>
            <div className={styles.buttonWrapper}>
              <Button variant="primary" onPress={() => push(RoutePath.PRODUCT_OFFERS)}>
                {t('typography.home.seeAllDiscountsButton')}
              </Button>
            </div>
            <div className={styles.cardsContainer}>
              {offersToDisplay.map((card, index) => (
                <AppStoreCard
                  key={index}
                  {...card}
                  category="dashboard"
                  selectOffer={setSelectedOfferName}
                  selectedOfferName={selectedOfferName}
                  tracking={{ name: card.tracking, category: 'dashboard' as CategoryName }}
                />
              ))}
            </div>
          </div>
          <ProfileCompletionProgress />
        </>
      )}
    </div>
  )
}
