import { useAuthDispatch, useAuthState, useMutation } from '@percent/cause-dashboard/common/hooks'
import { useTranslation } from 'react-i18next'
import { useServices } from '@percent/cause-dashboard/context/serviceContext/ServiceContext'
import { useQuery } from '@percent/cause-dashboard/common/hooks/useQuery/useQuery'
import { useState, useEffect } from 'react'
import { Loader, ErrorView } from '@percent/cause-dashboard/common/components'
import { SET_BANK_DETAILS } from '@percent/cause-dashboard/context/auth'
import { BankDetailsProps } from './BankDetails.types'
import { ProofBankSuccessModal } from './modal/ProofBankSuccessModal'
import { ProofBankErrorModal } from './modal/ProofBankErrorModal'
import { BankDetailsReview } from './bankDetailsReview/BankDetailsReview'
import styles from './BankDetails.module.scss'
import {
  BankDetailsView,
  useAddBankDetailsForm,
  BankDetailsReadView,
  BankDetailStatus
} from '@percent/domain/bank-details'
import { Text } from '@percent/lemonade'

const SUBMITTED_STATUSES = [BankDetailStatus.PENDING, BankDetailStatus.APPROVED]

export function BankDetails({ verifiedCauseOwner }: BankDetailsProps) {
  const { t } = useTranslation()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [openProofBankSuccessModal, setOpenProofBankSuccessModal] = useState(false)
  const [openProofBankErrorModal, setOpenProofBankErrorModal] = useState(false)
  const [isWaitingReview, setIsWaitingReview] = useState<boolean>(false)
  const [isAfterReview, setIsAfterReview] = useState<boolean>(false)
  const { bankDetailsService } = useServices()
  const { authDispatch } = useAuthDispatch()
  const { authState } = useAuthState()
  const [{ data: bankDetailsData, isLoading, errorMessage }, { refresh }] = useQuery(
    bankDetailsService.getBankDetails,
    {}
  )
  const handleOpenProofBankErrorModal = () => setOpenProofBankErrorModal(true)
  const handleCloseProofBankErrorModal = () => setOpenProofBankErrorModal(false)
  const handleCloseProofBankSuccessModal = () => {
    refresh()
    setOpenProofBankSuccessModal(false)
  }
  const handleOpenProofBankSuccessModal = () => {
    setIsWaitingReview(true)
    setOpenProofBankSuccessModal(true)
    authDispatch({
      type: SET_BANK_DETAILS,
      payload: {
        orgBankDetails: {
          ...authState.orgBankDetails,
          status: BankDetailStatus.PENDING
        }
      }
    })
  }

  const [{ isLoading: isPostLoading }, { apiFunc }] = useMutation(
    bankDetailsService.postBankDetails,
    () => handleOpenProofBankSuccessModal(),
    () => handleOpenProofBankErrorModal()
  )

  useEffect(() => {
    if (bankDetailsData?.status === BankDetailStatus.PENDING) {
      setIsWaitingReview(true)
    }

    if (bankDetailsData?.status === BankDetailStatus.APPROVED) {
      setIsAfterReview(true)
    }
  }, [bankDetailsData?.status])

  const bankDetailsStatusFlag = !isWaitingReview && !isAfterReview
  const isSubmitted = bankDetailsData?.status ? SUBMITTED_STATUSES.includes(bankDetailsData.status) : false

  const translations = {
    accountHolderName: t('typography.accountHolderName'),
    accountHolderDescription: t('typography.accountHolderDescription'),
    helperBankAccountText: t('typography.helperBankAccountText'),
    helperBankAccountLinkText: t('typography.helperBankAccountLinkText'),
    uploadDocumentPlaceholder: t('typography.uploadDocumentPlaceholder'),
    proofBankAccount: t('typography.proofBankAccount'),
    uploadDocument: t('button.uploadDocument'),
    submitBankDocuments: t('button.submitBankDocuments'),
    invalidDataTitle: t('bankDetails.validation.invalidDataTitle'),
    invalidDataDescription: t('bankDetails.validation.invalidDataDescription'),
    unknownValidationError: t('bankDetails.validation.unknownError')
  }

  const adjustedBankDetailsData = {
    ...bankDetailsData,
    addBankDetailsFormLabels: bankDetailsData?.requiredBankAccountDetails
  }

  const { formik, fields } = useAddBankDetailsForm({
    bankDetailsData: adjustedBankDetailsData,
    t,
    apiFunc,
    selectedFile
  })

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  const fileFieldText =
    ((selectedFile || (bankDetailsData?.status !== BankDetailStatus.REJECTED && bankDetailsData?.previousFilename)) &&
      selectedFile?.name) ||
    bankDetailsData?.previousFilename

  return (
    <>
      <ProofBankSuccessModal
        openProofBankSuccessModal={openProofBankSuccessModal}
        handleCloseProofBankSuccessModal={handleCloseProofBankSuccessModal}
      />
      <ProofBankErrorModal
        openProofBankErrorModal={openProofBankErrorModal}
        handleCloseProofBankErrorModal={handleCloseProofBankErrorModal}
      />
      {isWaitingReview && (
        <BankDetailsReview
          reviewBadgeText={t('typography.waitingReviewBadge')}
          reviewText={t('typography.waitingReviewText')}
          reviewLinkText={t('typography.reachOutToUsLink')}
          variant="info"
        />
      )}
      {isAfterReview && (
        <BankDetailsReview
          reviewBadgeText={t('typography.bankDetailsStatusText', {
            status: 'Verified'
          })}
          reviewText={t('typography.afterReviewText')}
          reviewLinkText={t('typography.reachOutToUsLink')}
          variant="success"
        />
      )}

      {!isWaitingReview && bankDetailsData?.status === 'rejected' && (
        <BankDetailsReview
          variant="error"
          reviewBadgeText={t('typography.bankDetailsStatusText', {
            status: 'Rejected'
          })}
        />
      )}

      {bankDetailsStatusFlag && (
        <Text size="small" fontWeight="regular" className={styles.bankDetailsFormHeader}>
          {t('typography.donationText')}
        </Text>
      )}
      {(!bankDetailsData?.bankAccountDetails || !isSubmitted) && (
        <BankDetailsView
          setSelectedFile={setSelectedFile}
          buttonFlag={bankDetailsStatusFlag}
          uploadButtonFlag={bankDetailsStatusFlag}
          disableUploadBtn={!verifiedCauseOwner}
          disabledField={isSubmitted}
          bankDetailsData={adjustedBankDetailsData}
          selectedFile={selectedFile}
          isPostLoading={isPostLoading}
          fileFieldText={fileFieldText}
          translations={translations}
          formik={formik}
          fields={fields}
        />
      )}
      {isSubmitted && bankDetailsData?.bankAccountDetails && (
        <BankDetailsReadView
          bankAccountDetails={bankDetailsData.bankAccountDetails}
          translations={translations}
          fileFieldText={fileFieldText}
        />
      )}
    </>
  )
}
