export const translationResources = {
  af: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/af-ZA.json')
  },
  'af-ZA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/af-ZA.json')
  },
  ar: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ar.json')
  },
  'ar-001': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ar.json')
  },
  'ar-DZ': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ar.json')
  },
  'ar-EG': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ar.json')
  },
  'ar-SA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ar.json')
  },
  'ar-AE': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ar.json')
  },
  'ar-EN': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ar.json')
  },
  'ar-KW': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ar.json')
  },
  'ar-QA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ar.json')
  },
  bs: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/bs-BA.json')
  },
  'bs-BA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/bs-BA.json')
  },
  bg: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/bg-BG.json')
  },
  'bg-BG': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/bg-BG.json')
  },
  yue: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hant.json')
  },
  'yue-HK': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hant.json')
  },
  'yue-Hant': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hant.json')
  },
  'yue-Hant-HK': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hant.json')
  },
  zh: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hans.json')
  },
  'zh-CN': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hans.json')
  },
  'zh-Hans': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hans.json')
  },
  'zh-Hans-CN': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hans.json')
  },
  'zh-HK': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hant.json')
  },
  'zh-Hant-HK': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hant.json')
  },
  'zh-TW': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hant.json')
  },
  'zh-Hant': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hant.json')
  },
  'zh-Hant-TW': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zh-Hant.json')
  },
  cs: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/cs-CZ.json')
  },
  'cs-CZ': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/cs-CZ.json')
  },
  da: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/da-DK.json')
  },
  'da-DK': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/da-DK.json')
  },
  nl: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/nl-NL.json')
  },
  'nl-NL': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/nl-NL.json')
  },
  'nl-BE': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/nl-NL.json')
  },
  en: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en.json')
  },
  'en-US': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en.json')
  },
  'en-AU': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-CA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-CY': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-ID': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-IN': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-IE': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-IL': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-PH': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-GB': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-BR': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-SA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-SG': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-NG': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-NZ': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-LU': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-MT': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-MY': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-HK': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-TH': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-ZA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-EG': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-KW': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-QA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  'en-VN': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/en-GB.json')
  },
  et: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/et-EE.json')
  },
  'et-EE': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/et-EE.json')
  },
  fi: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/fi-FI.json')
  },
  'fi-FI': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/fi-FI.json')
  },
  fr: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/fr-FR.json')
  },
  'fr-FR': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/fr-FR.json')
  },
  'fr-CA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/fr-CA.json')
  },
  'fr-CH': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/fr-FR.json')
  },
  'fr-BE': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/fr-FR.json')
  },
  'fr-LU': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/fr-FR.json')
  },
  de: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/de-DE.json')
  },
  'de-DE': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/de-DE.json')
  },
  'de-CH': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/de-CH.json')
  },
  'de-AT': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/de-DE.json')
  },
  'de-LI': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/de-DE.json')
  },
  'de-LU': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/de-DE.json')
  },
  el: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/el-GR.json')
  },
  'el-GR': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/el-GR.json')
  },
  he: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/he-IL.json')
  },
  'he-IL': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/he-IL.json')
  },
  iw: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/he-IL.json')
  },
  'iw-IL': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/he-IL.json')
  },
  hi: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/hi-IN.json')
  },
  'hi-IN': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/hi-IN.json')
  },
  hu: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/hu-HU.json')
  },
  'hu-HU': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/hu-HU.json')
  },
  id: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/id-ID.json')
  },
  'id-ID': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/id-ID.json')
  },
  in: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/id-ID.json')
  },
  'in-ID': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/id-ID.json')
  },
  it: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/it-IT.json')
  },
  'it-IT': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/it-IT.json')
  },
  'it-CH': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/it-IT.json')
  },
  ja: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ja-JP.json')
  },
  'ja-JP': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ja-JP.json')
  },
  ko: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ko-KR.json')
  },
  'ko-KR': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ko-KR.json')
  },
  lv: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/lv-LV.json')
  },
  'lv-LV': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/lv-LV.json')
  },
  lt: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/lt-LT.json')
  },
  'lt-LT': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/lt-LT.json')
  },
  ms: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ms-MY.json')
  },
  'ms-MY': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ms-MY.json')
  },
  no: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/no-NO.json')
  },
  'no-NO': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/no-NO.json')
  },
  nb: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/no-NO.json')
  },
  'nb-NO': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/no-NO.json')
  },
  pl: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/pl-PL.json')
  },
  'pl-PL': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/pl-PL.json')
  },
  pt: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/pt-PT.json')
  },
  'pt-BR': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/pt-BR.json')
  },
  'pt-PT': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/pt-PT.json')
  },
  ro: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ro-RO.json')
  },
  'ro-RO': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ro-RO.json')
  },
  mo: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ro-RO.json')
  },
  ru: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ru-RU.json')
  },
  'ru-RU': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ru-RU.json')
  },
  sk: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/sk-SK.json')
  },
  'sk-SK': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/sk-SK.json')
  },
  sl: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/sl-SI.json')
  },
  'sl-SI': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/sl-SI.json')
  },
  es: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-ES.json')
  },
  'es-ES': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-ES.json')
  },
  'es-419': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-419.json')
  },
  'es-EC': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-419.json')
  },
  'es-MX': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-419.json')
  },
  'es-US': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-419.json')
  },
  'es-AR': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-419.json')
  },
  'es-CO': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-419.json')
  },
  'es-CR': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-419.json')
  },
  'es-CL': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-419.json')
  },
  'es-PE': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-419.json')
  },
  'es-PR': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-419.json')
  },
  'es-GT': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/es-419.json')
  },
  sw: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/sw.json')
  },
  'sw-TZ': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/sw.json')
  },
  'sw-KE': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/sw.json')
  },
  sv: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/sv-SE.json')
  },
  'sv-SE': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/sv-SE.json')
  },
  tl: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/tl-PH.json')
  },
  'tl-PH': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/tl-PH.json')
  },
  th: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/th-TH.json')
  },
  'th-TH': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/th-TH.json')
  },
  tr: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/tr-TR.json')
  },
  'tr-TR': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/tr-TR.json')
  },
  uk: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/uk-UA.json')
  },
  'uk-UA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/uk-UA.json')
  },
  ur: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ur-IN.json')
  },
  'ur-IN': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ur-IN.json')
  },
  'ur-PK': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/ur-IN.json')
  },
  vi: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/vi-VN.json')
  },
  'vi-VN': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/vi-VN.json')
  },
  zu: {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zu-ZA.json')
  },
  'zu-ZA': {
    causeDashboard: require('@percent/cause-dashboard/i18n/data/en/en.json'),
    causeDashboardLoco: require('@percent/cause-dashboard/loco/zu-ZA.json')
  }
}
