import { StylesProvider } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

import { reportWebVitals, sendToVercelAnalytics } from '@percent/utility'

import { WithAxios } from '@percent/cause-dashboard/common/HOC/withAxios/WithAxios'
import { Fallback } from '@percent/cause-dashboard/common/components/fallback/Fallback'
import { logger } from '@percent/cause-dashboard/common/utility/logger'
import { AnalyticsContextController } from '@percent/cause-dashboard/context/analytics/AnalyticsContextController/AnalyticsContextController'
import { AuthContextController } from '@percent/cause-dashboard/context/auth'
import { LemonadeProvider } from '@percent/lemonade'
import { SplitFactory } from '@splitsoftware/splitio-react'
import { QueryParamProvider } from 'use-query-params'
import { App } from './app/app-entry'
import { splitSdkConfig } from './app/providers/SplitSdkProvider'
import TermlyBanner from './app/providers/TermlyBanner'
import { environment } from './config'
import { environments } from './constants/environments'
import { CountriesProvider } from './context/countries'
import { ServiceContextProvider } from './context/serviceContext/ServiceContextProvider'
import { goodstackPalette } from './theme/theme'
import { MantineProvider } from '@mantine/core'

if ([environments.PRODUCTION].includes(environment as string)) {
  Sentry.init({
    dsn: process.env.NX_SENTRY_DSN,
    environment: process.env.NX_REACT_APP_ENV,
    autoSessionTracking: true,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.33
  })
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={Fallback}
      onError={(error: unknown, componentStack: string | undefined, eventId: string) => {
        logger(error as Error)
      }}
    >
      <Router>
        <MantineProvider>
          {/* TODO - remove custom palette once lemonade theme has been updated */}
          <LemonadeProvider customPalette={goodstackPalette}>
            <AnalyticsContextController
              logger={logger}
              writeKey={process.env.NX_REACT_APP_SEGMENT_WRITE_KEY}
              options={{ disableClientPersistence: false }}
            >
              <SplitFactory config={splitSdkConfig}>
                <QueryParamProvider adapter={ReactRouter5Adapter}>
                  <StylesProvider injectFirst>
                    <ServiceContextProvider>
                      <CountriesProvider>
                        <AuthContextController>
                          <TermlyBanner />
                          <WithAxios>
                            <App />
                          </WithAxios>
                        </AuthContextController>
                      </CountriesProvider>
                    </ServiceContextProvider>
                  </StylesProvider>
                </QueryParamProvider>
              </SplitFactory>
            </AnalyticsContextController>
          </LemonadeProvider>
        </MantineProvider>
      </Router>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)

reportWebVitals(sendToVercelAnalytics)
