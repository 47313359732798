import { Divider, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'

import { Icon, ModalContent, ModalHeader, ModalTitle } from '@percent/lemonade'
import { BankDetailsValidationBox } from './bankDetailsValidationBox/BankDetailsValidationBox'
import { BankDataViewProps } from './BankDataView.types'
import styled from 'styled-components'
import {
  BankDetailsValidationEntry,
  BankDetailsValidationError,
} from '../../types/bankDetails.types'
import { BankDetailsValidationErrors } from './bankDetailsValidationErrors/BankDetailsValidationErrors'

const StyledInfoBoxDivider = styled(Divider)`
  margin-bottom: 18px;
  margin-top: 18px;
  background: #eceff3;
`

const StyledDescription = styled(Typography)`
  margin-top: 18px;
`

const CenteredIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
`

export function BankDataView({
  setFieldError,
  setFieldTouched,
  bankData,
  isValidated,
  fields,
  translations,
}: BankDataViewProps) {
  const [bankDataEntries, setBankDataEntries] = useState<
    BankDetailsValidationEntry[]
  >([])

  useEffect(() => {
    async function setValidationError(
      errList: BankDetailsValidationError[],
      apiKey: string,
      fieldKey: string
    ) {
      const error = errList.find((err) => err.field === apiKey)

      if (error) {
        await setFieldError(fieldKey, error.message)
        await setFieldTouched(fieldKey, true, false)
      }
    }

    const { title, description, noDataFound, foundBankData, ...labels } =
      translations

    if (bankData) {
      let entries: BankDetailsValidationEntry[] = []
      const foundData = {
        ...(bankData.branch || {}),
        ...(bankData.branch?.address || {}),
      } as Record<string, string>

      Object.keys(foundData).forEach((key) => {
        const label = labels[key as keyof typeof labels]

        if (label) {
          entries = [...entries, { key: label, value: foundData[key] }]
        }
      })

      setBankDataEntries(entries)

      setValidationError(
        bankData.validationErrors,
        'accountNumber',
        'accountNumber'
      )
      setValidationError(bankData.validationErrors, 'iban', 'iban')
      setValidationError(bankData.validationErrors, 'bic', 'bic')
      setValidationError(bankData.validationErrors, 'bankCode', 'bankCode')
      setValidationError(bankData.validationErrors, 'branchCode', 'branchCode')
    }
  }, [
    bankData,
    isValidated,
    setFieldError,
    setFieldTouched,
    setBankDataEntries,
    translations,
  ])

  return (
    <div>
      <ModalHeader>
        <ModalTitle>{translations.title}</ModalTitle>
      </ModalHeader>
      <ModalContent maxHeight="auto">
        {!isValidated && (
          <StyledDescription>{translations.description}</StyledDescription>
        )}
        {isValidated && bankData && bankData.success && (
          <CenteredIcon>
            <Icon name="check-circle" size={32} color={'success400'} />
            <br />
            <Typography>{translations.validDataMessage}</Typography>
          </CenteredIcon>
        )}
        {isValidated &&
          (!bankData?.success ||
            (bankData && bankData.validationErrors.length > 0)) && (
            <BankDetailsValidationErrors
              bankData={bankData}
              fields={fields}
              translations={translations}
            />
          )}
        {isValidated && (
          <>
            <StyledInfoBoxDivider />
            <BankDetailsValidationBox
              entries={bankDataEntries}
              translations={translations}
            />
          </>
        )}
      </ModalContent>
    </div>
  )
}
